.mat-dialog-container {
  padding: 0 !important;
}


.card-title {
  .title {
    font-size: 1.5em;
    font-weight: 700;
  }

  .close {
    cursor: pointer;
    font-size: x-large;
  }
}

.mat-mdc-dialog-surface {
  padding: 0;
}
