.mat-dialog-container {
  // padding: 0 !important;
}

.card-title {
  .title {
    font-size: 1em;
    font-weight: 600;
  }

  .close {
    cursor: pointer;
    font-size: x-large;
  }
}

.bold {
  font-weight: 700;
}

.mat-mdc-dialog-surface {
  padding: 0;
}

.table-div {
  height: 50vh;
  display: grid;
  place-content: center;
}
