.mat-dialog-container {
  padding: 0 !important;
}

.card-title {
  .title {
    font-size: 1.5em;
    font-weight: 700;
  }

  .close {
    cursor: pointer;
    font-size: x-large;
  }
}

.card-box {
  padding: 1em;
  color: white;
  margin-bottom: 1em;
  border-radius: 3px;
  cursor: pointer;
  p {
    font-size: 1.2em;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  h2 {
    text-align: end;
    font-size: 3rem;
    margin-bottom: 1rem;
  }
}
