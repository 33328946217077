import { CommonModule, NgIf } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChartType } from 'chart.js';
import {
  LoaderComponent,
  ModalHeaderComponent,
  configForms,
  AutocompleteComponent,
  InputBasicComponent,
  ICodeDescription,
  BtnComponent
} from 'ets-fe-ng-sdk';
import moment from 'moment';
import { lastValueFrom } from 'rxjs';
import { UtilityService } from '@Services/utility.service';
import { IETSChartOptions, NgChartDirective } from '@Shared/directives/ng-chart/ng-chart.directive';
import { SalesService } from 'projects/evolutics-sales-ui/src/app/sales/sales.service';

@Component({
    selector: 'app-renewal-analytics',
    imports: [
    NgChartDirective,
    LoaderComponent,
    ModalHeaderComponent,
    NgIf,
    AutocompleteComponent,
    InputBasicComponent,
    BtnComponent
],
    templateUrl: './renewal-analytics.component.html',
    styleUrl: './renewal-analytics.component.scss'
})
export class RenewalAnalyticsComponent implements OnInit {
  loading: boolean;
  form = new FormGroup({
    policyNo: new FormControl(null, Validators.required),
    month: new FormControl(null, Validators.required),
    year: new FormControl(null, [Validators.minLength(4), Validators.maxLength(4)]),
  });
  months: ICodeDescription[] = [
    { code: '1', description: 'January' },
    { code: '2', description: 'Febuaury' },
    { code: '3', description: 'March' },
    { code: '4', description: 'April' },
    { code: '5', description: 'May' },
    { code: '6', description: 'June' },
    { code: '7', description: 'July' },
    { code: '8', description: 'August' },
    { code: '9', description: 'September' },
    { code: '10', description: 'October' },
    { code: '11', description: 'November' },
    { code: '12', description: 'December' },
  ];
  showChart: boolean;
  constructor(
    private sS: SalesService,
    @Inject(MAT_DIALOG_DATA) public modalData: { policyNo?: string },
    public dialogRef: MatDialogRef<RenewalAnalyticsComponent>,
    private uS: UtilityService,
  ) {}

  ngOnInit(): void {
    //this.raCDLoad();
  }
  async raCDLoad(btn: BtnComponent) {
    btn.setLoader(true);
    try {
      const res = await lastValueFrom(this.sS.getRenewalAnalytics({ ...this.form.value }));
      const data = res[0];
      this.raCD.data.labels = ['Total Periodic Premium', 'Renewal Lost', 'Loss Ratio'];
      this.raCD.data.datasets = [
        {
          data: [data?.totalPeriodicPremium, data?.renewalLost, data?.lossRatio],
          backgroundColor: ['green', '#f48225', 'yellow'],
          hoverBackgroundColor: ['green', '#f48225', 'yellow'],
        },
      ];
      this.showChart = true;
    } catch (error) {
      this.uS.info(error, 0);
    }
    btn.setLoader(false);
  }

  raCD = {
    title: '',
    type: 'bar' as ChartType,
    data: {
      labels: [],
      datasets: [],
    },
    options: <IETSChartOptions>{
      responsive: true,
      scales: {
        xAxes: [
          {
            display: true,
            gridLines: {
              display: false,
            },
            ticks: {
              beginAtZero: true,
              autoSkip: false,
            },
          },
        ],
        yAxes: [
          {
            type: 'logarithmic',
            display: true,
            scaleLabel: {
              display: false,
              labelString: '',
              fontFamily: 'cursive',
              fontStyle: 'bold',
              fontSize: 11,
            },
            ticks: {
              beginAtZero: true,
              autoSkip: false,
              max: 20,
            },
          },
        ],
      },
      legend: {
        display: false,
      },
      onClick(event, activeElements) {},
    },
  };
}
